import OccurrenceTypeRepository from '@/shared/http/repositories/socialProject/occurrence-type'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import Pagination from '@/components/layout/Pagination/pagination.vue'
import Search from '@/components/layout/Search/search.vue'
import fab from 'vue-fab'
import Roles from '@/shared/mixins/hasRoles'
import { IsSuccessCode } from '@/shared/utils/API'
import { loading, toast } from '@/shared/utils/UIHelper'

export default {
  name: 'TipoDeOcorrência',

  components: {
    [BreadCrumb.name]: BreadCrumb,
    [Pagination.name]: Pagination,
    [Search.name]: Search,
    fab
  },

  mixins: [Roles],

  data: () => ({
     occurrenceTypes: [],
      labelSearch: 'Tipos de Ocorrência',
      showModalOccurrenceType: false,
      title: 'Novo Tipo de Ocorrência',
      id: null,
      searchText: null,
      fields: [
        {
          key: 'name',
          label: 'TIPOS DE OCORRÊNCIA',
          class: 'left-header-border text-center',
        },
        {
          key: 'action',
          label: 'AÇÕES',
          class: 'right-header-border text-center'
        }
      ],
      pagination: {
        count: 1,
        limit: 10,
        index: 1
      }
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Tipos de Ocorrência',
            active: true
          }
        ]
      }
    }
  },

  created() {
    this.getAll()
  },

  methods: {
    getAll() {
      loading.push()
      OccurrenceTypeRepository.GetAll(this.searchText, this.pagination)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.occurrenceTypes = res.data.data
          this.pagination = {
            count: res.data.count,
            limit: res.data.pagingLimit,
            index: res.data.pagingIndex
          }
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar tipo de ocorrência', 'ERRO')
        })
    },

    search(searchText) {
      this.searchText = searchText
      this.getAll()
    },

    onDelete() {
      loading.push()
      OccurrenceTypeRepository.Delete(this.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.showModalOccurrenceType = false
          this.getAll()
          this.showModalOccurrenceType = false
          toast.success('Tipo de Ocorrência excluído com sucesso!', 'EXCLUSÃO DE TIPO DE OCORRÊNCIA')
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao excluir tipo de ocorrência', 'ERRO')
        })
    },

    selectRow(id) {
      this.showModalOccurrenceType = true
      this.id = id
    }
  }
}
